import { Button, Modal } from '@warp-ds/react';
import React from 'react';

type LoginModalProps = {
    closeCallback: () => void;
    isOpen: boolean;
};

const LoginModal: (props: LoginModalProps) => JSX.Element = ({ isOpen, closeCallback }: LoginModalProps) => {
    const body: JSX.Element = (
        <div>
            <p>Hold deg oppdatert på nye og relevante jobbannonser fra dette firmaet. Logg inn for å kunne følge dem.</p>
        </div>
    );
    const footer: JSX.Element = (
        <>
            <Button quiet className="mr-8" onClick={closeCallback}>
                Ikke nå
            </Button>
            <Button primary href="/loginForm.html">
                Logg inn
            </Button>
        </>
    );
    return (
        <Modal title={'Logg inn'} open={isOpen} footer={footer} right onDismiss={closeCallback}>
            {body}
        </Modal>
    );
};

export default LoginModal;
