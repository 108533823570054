import pulse from '@finn-no/pulse-sdk';
import type { TrackerName } from '../common/types.js';
import type { TrackerData } from '@finn-no/pulse-sdk';

export type TrackingHooks = {
    followCompanyClick: () => void;
    unfollowCompanyClick: () => void;
};

export function trackFollowClick() {
    const followCompanyTrackingData: TrackerData = {
        name: 'Follow company',
        type: 'Click',
        object: {
            id: 'follow-company',
            type: 'UIElement',
            elementType: 'Link',
        },
        intent: 'Follow',
        target: {
            type: 'SavedList',
            id: 'id',
            name: 'Company profile list',
        },
    };
    pulse.trackEvent(followCompanyTrackingData);
}

export function trackUnfollowClick() {
    const unfollowCompanyTrackingData: TrackerData = {
        name: 'Unfollow company',
        type: 'Click',
        object: {
            id: 'unfollow-company',
            type: 'UIElement',
            elementType: 'Link',
        },
        intent: 'Unfollow',
        target: {
            type: 'SavedList',
            id: 'id',
            name: 'Company profile list',
        },
    };
    pulse.trackEvent(unfollowCompanyTrackingData);
}

const pulseTracker: TrackingHooks = {
    followCompanyClick: trackFollowClick,
    unfollowCompanyClick: trackUnfollowClick,
};

const consoleTracker: TrackingHooks = {
    followCompanyClick: () => console.log('Track event: Follow company at orgId:'),
    unfollowCompanyClick: () => console.log('Track event: Unfollow company at orgId:'),
};

const noOperationTracker: TrackingHooks = {
    followCompanyClick: () => {},
    unfollowCompanyClick: () => {},
};

export const resolveTracker = (trackerName: TrackerName): TrackingHooks => {
    switch (trackerName) {
        case 'pulse': {
            return pulseTracker;
        }
        case 'console': {
            return consoleTracker;
        }
        default: {
            return noOperationTracker;
        }
    }
};
