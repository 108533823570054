import React from 'react';
import Fallback from './Fallback.js';
import FollowCompany from './FollowCompany.js';
import type { FollowCompanyHydratationData } from '../common/types.js';

const doSubscribe = (url: string, requestObject: RequestInit) =>
    fetch(url, requestObject).then((r) => {
        if (r.status === 200) {
            return Promise.resolve();
        } else {
            return Promise.reject();
        }
    });

const FollowCompanyApp = (props: FollowCompanyHydratationData): React.ReactElement => {
    const requests = props.serverSubscriptionRequests;
    return props.followCompanyProps ? (
        <FollowCompany
            {...props.followCompanyProps}
            subscribe={requests ? () => doSubscribe(requests.subscribeUrl, requests.requestObject) : undefined}
            unsubscribe={requests ? () => doSubscribe(requests?.unsubscribeUrl, requests.requestObject) : undefined}
        />
    ) : (
        <Fallback />
    );
};

export default FollowCompanyApp;
