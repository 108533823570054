import { Alert, Button } from '@warp-ds/react';
import React, { useState } from 'react';
import LogInModal from './LogInModal.tsx';
import { resolveTracker } from './tracking.ts';
import { FollowCompanyProps } from '../common/types.ts';

const FollowCompany = ({
    followerCount: initialFollowerCount,
    isPreview,
    isUserSubscribed: initialSubscription,
    subscribe,
    trackerName,
    unsubscribe,
    userAuthenticated,
}: FollowCompanyProps) => {
    const [state, setState] = useState({
        followerCount: initialFollowerCount < 0 ? 0 : initialFollowerCount,
        isLoading: false,
        isUserSubscribed: initialSubscription,
        subscribeCallFailedMessage: '',
    });

    const [isLoginModalOpen, setLoginModalOpen] = React.useState(false);
    const toggleLoginModalOpen = () => setLoginModalOpen(!isLoginModalOpen);
    const tracking = resolveTracker(trackerName);

    const catchSubscriptionError = () => {
        setState((s) => ({
            ...s,
            isLoading: false,
            subscribeCallFailedMessage: 'Følg firma feilet. Prøv gjerne igjen, eller ta kontakt med kundeservice.',
        }));
    };

    const sendSubscriptionRequest = (subscriptionRequest: () => Promise<void>, newFollowerCount: number) => {
        subscriptionRequest()
            .then(() =>
                setState((prevState) => ({
                    ...prevState,
                    isUserSubscribed: !prevState.isUserSubscribed,
                    followerCount: newFollowerCount,
                    isLoading: false,
                })),
            )
            .catch(catchSubscriptionError);
    };

    const handleButtonClick = () => {
        setState((s) => ({
            ...s,
            subscribeCallFailedMessage: '',
            isLoading: true,
        }));
        if (state.isUserSubscribed && !!unsubscribe) {
            sendSubscriptionRequest(unsubscribe, state.followerCount - 1);
            tracking.unfollowCompanyClick();
        } else if (!state.isUserSubscribed && !!subscribe) {
            sendSubscriptionRequest(subscribe, state.followerCount + 1);
            tracking.followCompanyClick();
        }
    };
    return (
        <div>
            {userAuthenticated ? (
                <Button secondary loading={state.isLoading} className="min-w-full" disabled={isPreview} onClick={() => handleButtonClick()}>
                    {state.isUserSubscribed ? 'Slutt å følge firma' : 'Følg firma'}
                </Button>
            ) : (
                <>
                    <Button secondary disabled={isPreview} className="min-w-full" onClick={toggleLoginModalOpen}>
                        Følg firma
                    </Button>
                    <LogInModal closeCallback={toggleLoginModalOpen} isOpen={isLoginModalOpen} />
                </>
            )}
            <p className="s-text-subtle pt-8">
                {state.followerCount > 0 ? `${state.followerCount} følger dette firmaet` : 'Bli den første til å følge dette firmaet!'}
            </p>
            <Alert type="negative" show={!!state.subscribeCallFailedMessage}>
                {state.subscribeCallFailedMessage}
            </Alert>
        </div>
    );
};

export default FollowCompany;
