import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import FollowCompanyApp from './FollowCompanyApp.js';
import { FollowCompanyHydratationData } from '../common/types.js';

class PodletContent extends HTMLElement {
    constructor() {
        super();
        const dataNode = this.querySelector('[data-follow-company]') as HTMLElement;
        const initialState = dataNode && dataNode.textContent && (JSON.parse(dataNode.textContent) as FollowCompanyHydratationData);
        const innerNode = this.querySelector('[data-inner-node]');
        if (initialState && innerNode) {
            hydrateRoot(innerNode, <FollowCompanyApp {...initialState} />);
        } else {
            if (process.env.NODE_ENV !== 'production') {
                console.log('missing data element', this, initialState);
            }
        }
    }
}

if (!customElements.get('follow-company-podlet')) {
    customElements.define('follow-company-podlet', PodletContent);
}
